import PagesRedux from 'redux/pages';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import previousIcon from 'assets/icons/previous.svg';
import nextIcon from 'assets/icons/next.svg';
import style from './index.module.scss';

const Navigation = ({ PageID }) => {
  const { t } = useTranslation('page');

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const page = useSelector((state) => PagesRedux.selectors.pageSelectors.selectById(state, PageID) || {});

  const categoryId = page?.category?.id;

  const pages = useSelector((state) => state?.pages[categoryId]);

  useEffect(() => {
    setLoading(true);
    dispatch(PagesRedux.thunks.getPage(PageID));
    dispatch(PagesRedux.thunks.getAllPages({ category_id: categoryId, context: 'RULE_BOOK' }));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [PageID, categoryId]);

  const currentIndex = pages?.findIndex((item) => item.id == PageID);

  const previousPageId = currentIndex > 0 ? pages[currentIndex - 1].id : null;
  const nextPageId = currentIndex < pages?.length - 1 ? pages[currentIndex + 1].id : null;

  const goToPage = (id) => {
    history.push(`/page/${id}`);
  };

  return (
    (previousPageId || nextPageId) &&
    !loading && (
      <div className={style.navigationWrapper}>
        {previousPageId && (
          <div className={`${style.navigationBtn} ${style.prevBtn}`}>
            <button onClick={() => goToPage(previousPageId)}>
              <img src={previousIcon} />
              {t('previous')}
            </button>
          </div>
        )}
        {nextPageId && (
          <div className={`${style.navigationBtn} ${style.nextBtn}`}>
            <button onClick={() => goToPage(nextPageId)}>
              {t('next')}
              <img src={nextIcon} />
            </button>
          </div>
        )}
      </div>
    )
  );
};
export default Navigation;
