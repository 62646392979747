import { useHistory } from 'react-router-dom';
import style from './index.module.scss';
import { useTranslation } from 'react-i18next';

const SearchResultItem = ({ page }) => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const redirectToPage = (path) => {
    history.push(path);
  };

  return (
    <div className={style.SearchResultItemWrapper}>
      <span onClick={() => redirectToPage(`/page/${page.id}`)}>
        {page.breadcrumb.map((cat) => cat[`title_${i18n.language}`]).join(' > ')}
      </span>
    </div>
  );
};

export default SearchResultItem;
