import axios from 'axios';
import set from 'lodash/set';
import i18n from 'i18next';
import { API_BASE_URL } from 'utils/constants';

axios.defaults.baseURL = API_BASE_URL;

const injectLocale = (request) => {
  set(request, ['params', 'locale'], i18n.language);
  return request;
};

axios.interceptors.request.use(injectLocale);

export default axios;
