import { createAsyncThunk } from '@reduxjs/toolkit';
import CategoriesAPI from './services';

export const getCategory = createAsyncThunk('categories/getCategory', async (id, thunkAPI) => {
  try {
    const response = await CategoriesAPI.getCategory(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllCategories = createAsyncThunk('categories/getAllCategories', async (params, thunkAPI) => {
  try {
    const response = await CategoriesAPI.getAllCategories(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
