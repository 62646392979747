import style from './index.module.scss';
import { useMedia } from 'react-use';
import RuleBookItem from 'components/RuleBookItem';
import openArrowIcon from 'assets/icons/rulebook-arrow-open.svg';
import closeArrowIcon from 'assets/icons/rulebook-arrow-close.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

const OPEN_WIDTH = '290px';
const CLOSED_WIDTH = '68px';

const RuleBook = ({ children }) => {
  const { t } = useTranslation('ruleBook');
  const mdScreen = useMedia(`(max-width: 768px)`);
  const [open, setOpen] = useState(true);

  return (
    <div className={style.ruleBookWrapper}>
      {!mdScreen && (
        <div
          className={style.holdBlock}
          style={{
            width: open ? OPEN_WIDTH : CLOSED_WIDTH,
            flex: `0 0 ${open ? OPEN_WIDTH : CLOSED_WIDTH}`,
            maxWidth: open ? OPEN_WIDTH : CLOSED_WIDTH,
            minWidth: open ? OPEN_WIDTH : CLOSED_WIDTH,
          }}
        ></div>
      )}
      {!open && !mdScreen && <Divider type="vertical" className={style.openDivider} />}
      <aside
        className={style.ruleBook}
        style={
          !mdScreen
            ? {
                maxWidth: open ? OPEN_WIDTH : CLOSED_WIDTH,
                minWidth: open ? OPEN_WIDTH : CLOSED_WIDTH,
                width: open ? OPEN_WIDTH : CLOSED_WIDTH,
                flex: `0 0 ${open ? OPEN_WIDTH : CLOSED_WIDTH}`,
                zIndex: `${open ? 100 : -1}`,
              }
            : {
                display: 'none',
              }
        }
      >
        <h6>{t('rulebook-contents')}</h6>
        <div className={style.ruleBookContent}>
          {<RuleBookItem parentId={'root'} />}
          <div className={style.ruleBookContentBlock}></div>
        </div>
      </aside>
      {!mdScreen && (
        <img
          className={`${style.rulebookArrow} ${!open ? style.arrowOpen : ''}`}
          src={open ? closeArrowIcon : openArrowIcon}
          onClick={() => {
            setOpen(!open);
          }}
        />
      )}
      <div className={style.ruleBookChildren}>{children}</div>
    </div>
  );
};

export default RuleBook;
