import { useHistory } from 'react-router-dom';
import style from './index.module.scss';
import arrowIcon from 'assets/icons/arrow.svg';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t, i18n } = useTranslation('searchResult');

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className={style.BackButtonWrapper}>
      <button onClick={goBack}>
        <img src={arrowIcon} style={{ transform: i18n.language == 'ar' ? 'rotate(180deg)' : '' }} />
        <span>{t('back')}</span>
      </button>
    </div>
  );
};

export default BackButton;
