import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const pageSelectors = {
  ...adapter.getSelectors((state) => state.pages),
};

export const getPages = createSelector(
  (state) => state?.pages,
  (pages) => pages,
);

export const getMeta = createSelector(
  (state) => state?.pages,
  (pages) => pages?.meta,
);
