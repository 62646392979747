import { useEffect, useState } from 'react';
import { Pagination as AntdPagination, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';
import arrowLeft from 'assets/icons/navigation-arrow-left.svg';
import arrowRight from 'assets/icons/navigation-arrow-right.svg';
import arrowDown from 'assets/icons/navigation-arrow-down.svg';

const pageSizes = [
  { label: 5, value: 5 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const Pagination = ({ current, perPageSize, total, onChange }) => {
  const [page, setPage] = useState(current);
  const [pageSize, setPageSize] = useState(pageSizes[0].value);
  const { t, i18n } = useTranslation('pagination');

  const onPageChange = (page) => {
    setPage(page);
    onChange(page, pageSize);
  };

  const onPageSizeChange = (perPage) => {
    setPage(1);
    setPageSize(perPage);
    onChange(1, perPage);
  };

  useEffect(() => {
    setPage(current);
  }, [current]);

  useEffect(() => {
    setPageSize(perPageSize);
  }, [perPageSize]);

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a>
          <img className={style.arrows} src={arrowLeft} />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a>
          <img className={style.arrows} src={arrowRight} />
        </a>
      );
    }
    return originalElement;
  };

  const renderSuffixIcon = <img src={arrowDown} />;

  return (
    <div className={style.paginationWrapper}>
      {total > pageSizes[0].value && (
        <AntdPagination
          current={page}
          defaultCurrent={1}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          itemRender={itemRender}
          showQuickJumper={false}
          onChange={onPageChange}
          responsive={true}
          showLessItems={false}
          size={'small'}
          total={total}
        />
      )}
      {total > pageSizes[0].value && (
        <div className={style.paginationSelect}>
          <span>{t('return')}</span>
          <Select
            defaultValue={pageSize}
            value={pageSize}
            options={pageSizes}
            suffixIcon={renderSuffixIcon}
            onChange={onPageSizeChange}
          />
          <span>{t('results-per-page')}</span>
        </div>
      )}
    </div>
  );
};

export default Pagination;
