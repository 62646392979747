import axios from '../../utils/requestHelper';

const getCategory = (id) => axios.get(`/guests/categories/${id}`);

const getAllCategories = (params) => axios.get(`/guests/categories`, { params });

const CategoriesAPI = {
  getCategory,
  getAllCategories,
};

export default CategoriesAPI;
