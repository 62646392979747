import PagesRedux from 'redux/pages';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider, Skeleton } from 'antd';
import style from './index.module.scss';

const Page = () => {
  const { t, i18n } = useTranslation('page');
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let id = history.location.pathname.split('/').pop();

  const page = useSelector((state) => PagesRedux.selectors.pageSelectors.selectById(state, id) || {});

  useEffect(() => {
    setLoading(true);
    dispatch(PagesRedux.thunks.getPage(id));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [id]);

  const getTitleSequence = (page) => {
    return page.breadcrumb?.map((cat) => cat[`title_${i18n.language}`]).join(' > ');
  };

  const print = () => {
    const sectionToPrint = document.getElementById('pageContent');
    const titleToPrint = document.getElementById('pageTitle');

    const printWindow = window.open('', '_blank');

    const contentToPrint = `
        <div> 
          <div style="text-align: center;font-weight:bold;font-size:30px">${titleToPrint.innerHTML}</div>
          ${sectionToPrint.innerHTML}
        </div>
    `;

    printWindow.document.body.innerHTML = contentToPrint;

    printWindow.document.body.style.backgroundColor = '#111A19';

    const images = printWindow.document.body.getElementsByTagName('img');

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        image.style.maxWidth = '100%';
        image.style.height = 'auto';
      }
    }

    if (i18n.language == 'ar') {
      printWindow.document.body.dir = 'rtl';
    }
    printWindow.print();

    setTimeout(() => {
      printWindow.close();
    }, 500);
  };

  return (
    <div className={style.PageWrapper}>
      {loading ? (
        <Skeleton paragraph={{ rows: 0 }} />
      ) : (
        <>
          <div>
            <span className={style.titleSequence}>{getTitleSequence(page)}</span>
          </div>
          <div className={style.PageTitleWrapper}>
            <div id="pageTitle" className={style.PageTitle}>
              {i18n.language == 'en' ? page.title_en : page.title_ar}
            </div>
            <div className={style.PrintBtn}>
              <button onClick={print}>{t('print')}</button>
            </div>
          </div>
        </>
      )}
      <Divider />
      {loading ? (
        <Skeleton />
      ) : (
        <div id="pageContent" className={style.content}>
          {i18n.language == 'en' ? (
            <div dangerouslySetInnerHTML={{ __html: page.description_en }} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: page.description_ar }} />
          )}
        </div>
      )}
    </div>
  );
};

export default Page;
