import axios from '../../utils/requestHelper';

const getPage = (id) => axios.get(`/guests/pages/${id}?view=show`);

const getAllPages = (params) => axios.get(`/guests/pages?view=index`, { params });

const PagesAPI = {
  getPage,
  getAllPages,
};

export default PagesAPI;
