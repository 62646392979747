import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const Footer = () => {
  const { t, i18n } = useTranslation('footer');

  return (
    <div className={style.siteFooter}>
      <div className={style.logoWrapper}>
        <img src="/icon.svg" />
      </div>
      <div className={style.buttonText}>
        <div className={style.copyRight}>{t('copyright')}</div>
        {/* <div className={style.createdBy}>
          {t('designed-developed')}{' '}
          <span>
            <a href="https://thewebops.com/" target="_blank" rel="noreferrer">
              WebOps
            </a>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
