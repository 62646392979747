import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getCategory, getAllCategories } from './thunks';

export const slice = createSlice({
  name: 'category',
  initialState: { root: [] },
  extraReducers: (builder) => {
    builder.addCase(getCategory.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.category) return;
      adapter.setOne(state, data.category);
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.categories) return;
      const { arg } = meta;
      if (arg?.parent_id) state[arg.parent_id] = data.categories;
      else state.root = data.categories;
    });
  },
});
const Categories = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Categories;
