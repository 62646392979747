import { useTranslation } from 'react-i18next';
import searchImage from 'assets/images/search.svg';
import style from './index.module.scss';

const NoSearchResult = () => {
  const { t, i18n } = useTranslation('searchResult');

  return (
    <div className={style.NoSearchResultWrapper}>
      <img src={searchImage} />
      <p className={style.desc_1}>{t('Description_1')}</p>
      <p className={style.desc_2}>{t('Description_2')}</p>
    </div>
  );
};

export default NoSearchResult;
