import { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import style from './index.module.scss';
import searchIcon from 'assets/icons/search.svg';

const SearchBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation('header');
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const query = queryParams.get('q');
  const [value, setValue] = useState(query);
  const lgScreen = useMedia(`(max-width: 992px)`);
  const mdScreen = useMedia(`(max-width: 768px)`);
  const smScreen = useMedia(`(max-width: 576px)`);

  const customStyle = {
    width: smScreen ? '172px' : mdScreen ? `317px` : lgScreen ? '400px' : '460px',
    borderRadius: '8px',
  };

  const redirectToPage = (path) => {
    history.push(path);
  };

  const onSearch = (value) => {
    let newValue = validSearch(value);
    if (newValue) redirectToPage(`/search?q=${newValue}`);
  };

  const validSearch = (value) => {
    if (value.trim() === '') return false;
    return value.trim();
  };

  useEffect(() => {
    const query = queryParams.get('q');

    setValue(query || '');
  }, [location.search]);

  const renderSearchIcon = (
    <div className={style.SearchBarButton} onClick={() => onSearch(value)}>
      <img src={searchIcon} />
    </div>
  );

  return (
    <div className={style.SearchBarWrapper}>
      <Input
        style={customStyle}
        defaultValue={value}
        value={value}
        onPressEnter={() => onSearch(value)}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('search')}
        addonAfter={renderSearchIcon}
      />
    </div>
  );
};

export default SearchBar;
