import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pages from 'redux/pages';
import BackButton from 'components/BackButton';
import style from './index.module.scss';
import SearchResultItem from 'components/SearchResultItem';
import NoSearchResult from 'components/NoSearchResult';
import Pagination from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

const SearchResult = () => {
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const query = queryParams.get('q');
  const { t, i18n } = useTranslation('searchResult');
  const [filters, setFilters] = useState({ query: '', page: 1, per_page: 5 });
  const pages = useSelector(Pages.selectors.getPages);
  const pagesMeta = useSelector(Pages.selectors.getMeta);
  const [loading, setLoading] = useState(true);

  const onChange = (page, per_page) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        page,
        per_page,
      };
    });
  };

  useEffect(() => {
    setLoading(true);
    if (filters.query) dispatch(Pages.thunks.getAllPages(filters));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch, filters]);

  useEffect(() => {
    setFilters({
      page: 1,
      per_page: 5,
      query,
    });
  }, [query]);

  const renderSearchResultQuery = () => (
    <div className={style.searchResultQueryWrapper}>
      <p>
        {t('search-title')} <span>{query}</span>
      </p>
      <p className={style.searchResultQueryTotal}>
        {pagesMeta.total_entries} {t('results')}
      </p>
    </div>
  );

  return (
    <div className={style.searchResultWrapper}>
      <div className={style.backButtonWrapper}>
        <BackButton />
      </div>
      {renderSearchResultQuery()}
      <div className={style.resultListWrapper}>
        {loading ? (
          <Skeleton />
        ) : pages.allPages.length > 0 ? (
          pages.allPages.map((page) => <SearchResultItem page={page} />)
        ) : (
          <NoSearchResult />
        )}
      </div>
      <div className={style.paginationWrapper}>
        {!loading && (
          <Pagination
            current={filters.page}
            perPageSize={filters.per_page}
            total={pagesMeta.total_entries}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResult;
