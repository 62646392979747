import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getPage, getAllPages } from './thunks';

export const slice = createSlice({
  name: 'page',
  initialState: adapter.getInitialState({ meta: {}, allPages: [] }),
  extraReducers: (builder) => {
    builder.addCase(getPage.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.page) return;
      adapter.setOne(state, data.page);
    });
    builder.addCase(getAllPages.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      const { arg } = meta;
      if (!data.pages) return;
      if (arg.context == 'RULE_BOOK') {
        if (arg?.category_id) state[arg.category_id] = data.pages;
        else state.root = data.pages;
      } else {
        state.meta = data.meta;
        state.allPages = data.pages;
      }
    });
  },
});
const Pages = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Pages;
