import { createAsyncThunk } from '@reduxjs/toolkit';
import PagesAPI from './services';

export const getPage = createAsyncThunk('pages/getPage', async (id, thunkAPI) => {
  try {
    const response = await PagesAPI.getPage(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllPages = createAsyncThunk('pages/getAllPages', async (params, thunkAPI) => {
  try {
    const response = await PagesAPI.getAllPages(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
