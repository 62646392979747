import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const categorySelectors = {
  ...adapter.getSelectors((state) => state.categories),
};

export const getCategories = createSelector(
  (state) => state?.categories,
  (categories) => categories,
);

export const getCategoriesById = createSelector(
  (state) => state?.categories,
  (_, categoryId) => categoryId, // Additional argument for the category ID
  (categories, categoryId) => categories[categoryId] || categories.root,
);
