import { useTranslation } from 'react-i18next';
import homeImage from 'assets/images/home.svg';
import style from './index.module.scss';

const HomePage = () => {
  const { t, i18n } = useTranslation('homePage');

  return (
    <div className={style.HomePageWrapper}>
      <img src={homeImage} />
      <p className={style.desc_1}>{t('Description_1')}</p>
      <p className={style.desc_2}>{t('Description_2')}</p>
    </div>
  );
};

export default HomePage;
