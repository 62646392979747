import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import GuestRoute from 'router/GuestRoute';
import Home from 'screens/Guest/Homepage';
import SearchResult from 'screens/Guest/SearchResult';
import Page from 'screens/Guest/Page';
import AboutUs from 'screens/Guest/AboutUs';
import NotFound from 'screens/Guest/NotFound';

export const history = createBrowserHistory();

const Router = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <Route
      render={() => (
        <Switch>
          <GuestRoute exact path="/" component={Home} />
          <GuestRoute exact path="/search" component={SearchResult} />
          <GuestRoute exact path="/page/:id" component={Page} />
          <GuestRoute exact path="/about-us" component={AboutUs} />
          <GuestRoute exact path="*" component={NotFound} />
        </Switch>
      )}
    />
  );
};

export default Router;
