import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'router';
import Categories from './categories';
import Pages from './pages';

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    categories: Categories.slice.reducer,
    pages: Pages.slice.reducer,
  });

export default rootReducer;
