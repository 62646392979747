import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import 'translation/i18n';
import { Helmet } from 'react-helmet';

import store from './redux/store';
import { history } from './router';
import Router from './router';
import './index.scss';

const Root = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en';
  const direction = i18n.dir(currentLanguage);

  return (
    <Provider store={store}>
      <Helmet
        htmlAttributes={{
          lang: currentLanguage,
        }}
      >
        <title>EFB SOP</title>
        <meta property="og:title" content="EFB SOP" />
        <meta property="og:description" content="EFB SOP" />
        <link rel="apple-touch-icon" sizes="180x180" href="logo.svg" />
        <link rel="icon" type="image/png" href="logo.svg" sizes="16x16" />

        <meta property="og:image" content="" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="200" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />

        <meta property="title" content="Efb Sop" />
        <meta property="description" content="Efb Sop" />
        <meta property="image" content="" />
        <meta property="url" content="" />
        <meta property="type" content="website" />
        <meta property="locale" content="en_GB" />
      </Helmet>
      <ConnectedRouter history={history}>
        <ConfigProvider direction={direction}>
          <Router />
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
